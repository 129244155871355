import React, { useState, useEffect } from 'react';
import { Flex, Text, Icon, IconProps } from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import { useNavigate } from 'react-router-dom';
import type { TileComponentProps } from '../tile-component';
import { TileButton } from '../tile-button';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';

export interface TileListingStatusProps extends TileComponentProps {}

export const TileListingStatus: React.FC<TileListingStatusProps> = props => {
  const { componentTheme } = props;
  const [currentStatus, setCurrentStatus] = useState('Loading');
  const { data: globalUser } = useGetGlobalUserQuery();

  useEffect(() => {
    const id = globalUser?.account?.listingId || { id: null };

    if (!id) {
      setCurrentStatus('IncompleteSetup');
    } else {
      setCurrentStatus('Complete');
    }
  }, [globalUser?.account?.listingId]);

  const statusComponents = {
    IncompleteSetup,
    Complete,
    Loading,
  };

  const StatusComponent = statusComponents[currentStatus];

  return (
    <Flex
      className={`tile-tileListingStatus__${currentStatus.toLowerCase()}`}
      alignItems="center"
      flexDirection="column"
      flexGrow={1}
      sx={componentTheme}
    >
      <StatusComponent />
    </Flex>
  );
};

const IncompleteIcon = (props: IconProps) => (
  // @ts-ignore
  <Icon viewBox="0 0 34 34" {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor">
      <path strokeWidth="1.036" d="M17 33c8.837 0 16-7.163 16-16S25.837 1 17 1" />
      <path strokeDasharray="3" strokeWidth="1.036" d="M17 1C8.163 1 1 8.163 1 17s7.163 16 16 16" />
      <path
        d="m11.762 17.486 4.304 3.419m6.172-8.276-6.172 8.276"
        strokeLinecap="round"
        strokeWidth="3.109"
      />
    </g>
  </Icon>
);

export const IncompleteSetup = () => {
  const navigate = useNavigate();

  const navToBLP = () =>
    navigate('/business-listings-profile', {
      state: { initialStep: 'basic-business-information' },
    });

  return (
    <>
      <IncompleteIcon color="orange.300" boxSize="82px" mb={2} />
      <Text textStyle="md" mb="auto" px={4} textAlign="center">
        Complete setting up your Business Listings Profile.
      </Text>
      <TileButton onClick={navToBLP}>Complete Profile</TileButton>
    </>
  );
};

export const Complete = () => {
  const navigate = useNavigate();

  const navToBLP = () => navigate('/business-listings-profile');

  return (
    <>
      <Text textStyle="md" mb="auto" px={4} textAlign="center">
        Your business is listed in Google My Business and the Vastly directory. Manage your profile
        to keep your listings in sync.
      </Text>
      <TileButton onClick={navToBLP}>Manage Profile</TileButton>
    </>
  );
};

export const Loading = () => <AppSpinner containerStyles={{ height: '100%' }} />;
