import React from 'react';
import { Heading, Text, Flex, Box, Button, Divider } from '@companydotcom/potion';
import { CircleEnvelopeIcon } from '@companydotcom/ui';
import { useTranslation } from 'react-i18next';
import { useSource } from '@companydotcom/providers';

export const VerifyEmailStdPage = (props: any) => {
  const { isSending, isResent, productName, resendVerification } = props;
  const source = useSource();
  const { t } = useTranslation();
  const emailData = localStorage.getItem('welcomeemail') || '';
  const emailJson = JSON.parse(emailData);
  return (
    <Flex
      className="verify-email-page"
      flexDirection="column"
      alignItems="center"
      px={4}
      bg="white"
      textAlign="center"
    >
      <Box mt={[12, 16]} mb={[8]}>
        <CircleEnvelopeIcon />
      </Box>

      {(isSending && <Heading size="hs-2xl">{t('subpage.verifyEmail.isSending')}</Heading>) ||
        (isResent && <Heading size="hs-2xl">{t('subpage.verifyEmail.emailResent')}</Heading>) || (
          <Heading size="hs-2xl">
            {t('subpage.verifyEmail.welcomeMessage', {
              sourceName: source?.sourceId === 'company' ? 'grandio' : 'Vastly',
            })}
          </Heading>
        )}
      {productName && !isResent && (
        <>
          <Heading size="hs-md" mt={3}>
            {t('subpage.verifyEmail.thanksForPurchasing', { productName })}
          </Heading>
          <Divider borderColor="gray.300" my={9} mx="auto" width={333} />
        </>
      )}

      <Text textStyle="lg" mt={12}>
        {t('subpage.verifyEmail.sentTo')}
        <Button
          variant="link"
          fontSize="lg"
          cursor="text"
          whiteSpace="normal"
          _hover={{
            textDecoration: 'none',
          }}
        >
          {emailJson.email}
        </Button>
      </Text>
      <Text textStyle="md" mt={12} maxW="md">
        {t('subpage.verifyEmail.confirmText')}
      </Text>
      <Text textStyle="sm" mt={12} mb={[13, 16]}>
        {t('subpage.verifyEmail.cantFindEmail')}

        <Button
          fontSize="sm"
          variant="link"
          isLoading={isSending}
          onClick={() => resendVerification()}
        >
          {t('subpage.verifyEmail.sendAgain')}
        </Button>
      </Text>
    </Flex>
  );
};
