import { useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import { parsePhoneNumber } from 'libphonenumber-js';
import ReCaptcha from 'react-google-recaptcha';
import {
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  VStack,
  Center,
  useDisclosure,
  Link,
  ButtonGroup,
  UnorderedList,
  ListItem,
} from '@companydotcom/potion';
import {
  InputField,
  FontAwesomeIcon,
  CircleExclamationIcon,
  useGetCurrentlySelectedCountry,
  PhoneNumberInputField,
} from '@companydotcom/ui';
import { useSource } from '@companydotcom/providers';
// import { EnrollmentSvc } from '@companydotcom/services';
import { useNavigate } from 'react-router-dom';
import { companyHelpers } from '@companydotcom/helpers';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import {
  enrollmentSetValidEmail,
  optOutByExternalIdentifier,
} from '../../../services/enrollment/old-api/enrollment-svc';
import {
  useRecaptchaVerificationMutation,
  // useEnrollmentSetValidEmailMutation,
  // useOptOutByExternalIdentifierMutation,
} from '../../../services/enrollment/enrollment-api';
import yup from '../../../lib/yup';

// const { recaptchaVerification } = EnrollmentSvc();

const CancelBusinessFormSchema = () =>
  yup.object().shape({
    businessName: yup.string().required('common.inputs.businessName.errorLegal'),
    email: yup.string().required('common.inputs.email.error'),
    mid: yup.string().required('common.inputs.mid.error'),
    phone: yup.string().phone().required('common.inputs.phoneNumber.error').nullable(),
    zipCode: yup
      .string()
      .required('common.inputs.postalCode.error')
      .test('zipCode', 'common.inputs.postalCode.validation', val =>
        companyHelpers.validateZipCode(val),
      ),
  });

interface CancelBusinessFormProps {
  formLabels?: any;
  mid?: string;
  businessName?: string;
  zipCode?: string;
  phone?: string;
  email?: string;
}

export const CancelBusiness = ({
  mid,
  businessName,
  zipCode,
  phone,
  email,
  formLabels,
}: CancelBusinessFormProps) => {
  //const [enrollmentSetValidEmail, { isLoading }] = useEnrollmentSetValidEmailMutation();
  // const [optOutByExternalIdentifier, { isLoading: optOutLoading }] =
  //   useOptOutByExternalIdentifierMutation();
  const source = useSource();
  const captcha = useRef(null);
  const navigate = useNavigate();
  const captchaEnabled = process.env.REACT_APP_CAPTCHA_ENABLED === 'true' || false;
  const [captchaStatus, setCaptchaStatus] = useState(!captchaEnabled);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [recaptchaVerification] = useRecaptchaVerificationMutation();
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();

  const defaultValues: FieldValues = {
    mid: mid || '',
    businessName: businessName || '',
    zipCode: zipCode || '',
    phone: phone ? parsePhoneNumber(phone, country).number : '',
    email: email || '',
  };

  const { register, control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CancelBusinessFormSchema()),
    defaultValues,
    context: { country },
  });

  const { isSubmitting, isValid, errors } = formState;

  const handleOptOut = async ({ email, mid }: typeof defaultValues) => {
    try {
      const optOutStatus = await optOutByExternalIdentifier({
        email,
        source: source?.sourceId,
        externalIdentifier: mid,
      });
      if (!optOutStatus.error) {
        navigate('/cancelconfirmed');
      } else {
        throw new Error('Opt out failed');
      }
    } catch (err) {
      navigate('/support');
    }
  };

  const handleEnroll = async (values: typeof defaultValues) => {
    try {
      //changed the source paramater to sourceID
      const verifyStatus = await enrollmentSetValidEmail({
        email: values.email,
        source: source.sourceId,
        externalIdentifier: values.mid,
        businessName: values.businessName,
        zip: values.zipCode,
        phone: values.phone.replace(/\D/g, '').slice(-10),
      });

      if (!verifyStatus.error) {
        const mailJson = JSON.stringify({ email: values.email });
        localStorage.setItem('welcomeemail', mailJson);
        navigate('/verifystd', { state: { email: values.email } });
      } else {
        throw new Error('Verify Business failed');
      }
    } catch (error) {
      navigate('/support');
    }
  };

  const verifyCaptcha = async (token: string) => {
    console.log('Verification Token: ', token);
    const verificationResponse = await recaptchaVerification({ token }).unwrap();
    if (verificationResponse.success) {
      console.log('Successful verificationse: ', verificationResponse);
      setCaptchaStatus(true);
    } else {
      console.log('Unsuccessful verification response: ', verificationResponse);
      setCaptchaStatus(false);
    }
  };

  const packageData = [
    {
      name: 'Tech Support',
      features: [
        'System Monitoring',
        'Diagnostic scan',
        'Network scan',
        'PC tune-up',
        'Live phone support',
        'Chat and ticket support',
      ],
    },
    {
      name: 'Tax Advice',
      features: [
        'Unlimited advice',
        'Licensed tax professionals',
        'Review of prior year return',
        'Resource center',
        'Audit defense',
      ],
    },
  ];
  return (
    <Center
      flexDirection="column"
      maxWidth={704}
      width="full"
      mx="auto"
      layerStyle="formContainer"
      py={[12, null, 14]}
      px={4}
    >
      <Heading size="hs-2xl" mb={4}>
        Decline Invitation
      </Heading>
      <Text textStyle="md" mb={8}>
        Please enter your information to decline your invitation.
      </Text>
      <Box as="form" maxW={320} width="full">
        <VStack spacing={6}>
          <InputField
            register={register}
            name="mid"
            autoFocus
            label="Location ID"
            errors={errors}
          />
          <InputField
            register={register}
            name="businessName"
            label="Legal Business Name"
            errors={errors}
          />
          <InputField
            register={register}
            name="zipCode"
            label="Business ZIP Code"
            errors={errors}
          />
          <PhoneNumberInputField
            name="phone"
            label="Business Phone"
            control={control}
            country={country}
            onCountryChange={onCountryChange}
            errors={errors}
          />

          <InputField
            register={register}
            name="email"
            label="Email"
            errors={errors}
            helperText="This will be used as your login"
          />
          {captchaEnabled && (
            <ReCaptcha
              ref={captcha}
              size="normal"
              sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
              render="explicit"
              onChange={verifyCaptcha}
            />
          )}
        </VStack>
        <Box mt={10} mb={4} sx={{ textAlign: 'center' }}>
          <Button
            onClick={() => {
              onOpen();
            }}
            size="lg"
            variant="outline"
            isLoading={isSubmitting}
            isDisabled={!captchaStatus || !isValid}
            rightIcon={<FontAwesomeIcon icon={faArrowRightLong} />}
          >
            {formLabels && formLabels.buttonText ? formLabels.buttonText : 'Decline Invitation'}
          </Button>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <Flex p={[3, null, 6]} flexDirection="column" alignItems="center" textAlign="center">
            <CircleExclamationIcon color="warning" mt={10} mb={8} />

            <Heading size="hs-2xl" mb={[4]}>
              Decline the Invitation?
            </Heading>
            <Text textStyle="lg">You will lose access to the services below</Text>
            <Flex
              flexDirection={['column', null, 'row']}
              bg="dashboard_background"
              marginLeft={68}
              marginTop={0}
              py={[8, 5]}
              px={[3, 8, 15, 12]}
              width={0.8}
              alignItems="flex-start"
              justifyContent="center"
            >
              {packageData.map(product => (
                <Product data={product} key={product.name} />
              ))}
            </Flex>
            <ButtonGroup spacing={6} size="lg" flexDirection={['column', null, 'row']} mb={4}>
              <Button variant="outline" onClick={handleSubmit(handleEnroll)}>
                No, Accept
              </Button>
              <Button onClick={handleSubmit(handleOptOut)}>Yes, Decline</Button>
            </ButtonGroup>
            <Text textStyle="sm" px={4}>
              By accepting you are agreeing to Vastly’s{' '}
              <Link
                isExternal
                href={`${process.env.REACT_APP_GRANDIO_URL}/terms-of-use`}
                target="_blank"
              >
                Terms of Use.
              </Link>
            </Text>
          </Flex>
        </ModalContent>
      </Modal>
    </Center>
  );
};

const Product = ({ data }: { data: { name: string; features: string[] } }) => {
  return (
    <Flex
      flexDirection="column"
      p="6px"
      pb={[5, null, 0]}
      px={3}
      textAlign="justify"
      mr={[0, 5, 5]}
    >
      <Text textStyle="md" minWidth={172} fontWeight={500}>
        {data.name}
      </Text>

      <UnorderedList>
        {data.features.map((feat: string, i: number) => (
          <ListItem key={i}>{feat}</ListItem>
        ))}
      </UnorderedList>
    </Flex>
  );
};
