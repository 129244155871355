import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Center,
  Button,
  Heading,
  ButtonGroup,
  Stack,
  Radio,
  RadioGroup,
  error,
  useBoolean,
} from '@companydotcom/potion';
import { AppSpinner } from '@companydotcom/ui';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Listing, UserTile } from '@companydotcom/types';
import { useGetGlobalUserQuery } from '../../../../services/user/user-api';
import {
  useGetVerificationOptionsMutation,
  useStartVerificationMutation,
} from '../../../../services/listings/listing-api';
import { useAwaitableFacade } from '../../../../hooks';
//import { companyConstants } from '@companydotcom/helpers';
//import { useMitt } from '@companydotcom/providers';

const getVerificationBusinessSchema = () =>
  yup.object().shape({
    selectedOption: yup
      .mixed()
      .oneOf(['SUPPORT_EMAIL', 'SCHEDULE', 'EMAIL', 'SMS', 'PHONE_CALL', 'NOT_NOW']),
  });

interface SchemaProps {
  selectedOption: string;
}

export interface VerificationBusinessProps {
  setVerificationOption?: (arg: string) => void;
  listingId?: string;
  setShowPendingVerification?: (arg: boolean) => void;
  setListing?: (arg: Listing) => void;
  BLPTile?: UserTile;
  // listingSvc?: any;
  goToStep?: (arg: string | number) => void;
  previousStep?: () => void;
}

export const VerificationBusiness: React.FC<VerificationBusinessProps> = props => {
  const {
    setVerificationOption,
    listingId,
    setShowPendingVerification,
    setListing,
    // BLPTile,
    // listingSvc,
    goToStep,
    previousStep,
  } = props;
  const { t } = useTranslation();
  const { data: globalUser } = useGetGlobalUserQuery();
  const [getVerificationOptions] = useGetVerificationOptionsMutation();
  const [startVerification] = useStartVerificationMutation();
  const [loading, setLoading] = useBoolean(true);
  const [permittedVerificationOptions, setPermittedVerificationOptions] = useState<string[]>([
    'SUPPORT_EMAIL',
    'SCHEDULE',
    'NOT_NOW',
  ]);
  const snsInterface = useAwaitableFacade(globalUser, 'listing');
  const defaultValues = {
    selectedOption: 'SUPPORT_EMAIL',
  };

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = useForm<SchemaProps>({
    mode: 'onChange',
    resolver: yupResolver(getVerificationBusinessSchema()),
    defaultValues,
  });

  //const { emitter } = useMitt();

  const allVerificationOptions = [
    {
      label: 'Send me a postcard and follow up with me via email',
      value: 'SUPPORT_EMAIL',
      default: true,
    },
    {
      label: 'Send me a postcard and I’ll schedule a time to get help setting up',
      value: 'SCHEDULE',
    },
    {
      label: 'Call my business phone with the verification code',
      value: 'PHONE_CALL',
    },
    {
      label: 'Send a text message with the verification code to my phone',
      value: 'SMS',
    },
    {
      label: 'Send me an email with the verification code',
      value: 'EMAIL',
    },
    {
      label: 'I am not interested in verifying my business right now',
      value: 'NOT_NOW',
    },
  ];

  const setVerification = useCallback(async () => {
    if (!listingId) {
      setPermittedVerificationOptions(['SUPPORT_EMAIL', 'SCHEDULE', 'NOT_NOW']);
      setLoading.off();
      error({ condition: true, message: 'no listing id provided to lookup verification options' });
    }
    if (globalUser && listingId) {
      await getVerificationOptions({ listingId, snsInterface })
        .unwrap()
        .then(options => {
          setPermittedVerificationOptions(options);
          setLoading.off();
        })
        .catch(err => {
          error({ condition: true, message: err });
          setPermittedVerificationOptions(['SUPPORT_EMAIL', 'SCHEDULE', 'NOT_NOW']);
          setLoading.off();
        });
    }
  }, [getVerificationOptions, globalUser, listingId, setLoading, snsInterface]);

  useEffect(() => {
    setVerification();
    // listingSvc
    //   .getVerificationOptions(listingId)
    //   .then(({ data: options }: { data: any }) => {
    //     // @ts-ignore
    //     setPermittedVerificationOptions(options);
    //     setLoading(false);
    //   })
    //   .catch((err: any) => {
    //     error({ condition: true, message: err });
    //     setPermittedVerificationOptions(['SUPPORT_EMAIL', 'SCHEDULE', 'NOT_NOW']);
    //     setLoading(false);
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      //Note : BLP Tile state is not changed to Pending Verification state due to this emit action and we have below logic is to update the tile state. We cannot run parrallely emitter emit and listing svc req.
      // emitter.emit(companyConstants.tileActions.transitionState, {

      //   tileId: BLPTile?.tileId,
      //   productId: BLPTile?.productId,
      //   stateCurrent: values.selectedOption === 'NOT_NOW' ? 'active' : 'pre-active',
      //   statePrevious: values.selectedOption === 'NOT_NOW' ? 'active' : 'pre-active',
      //   product: {
      //     slug: 'blp',
      //   },
      // });
      if (globalUser && listingId) {
        await startVerification({
          snsInterface,
          listingId,
          verificationMethod: values.selectedOption,
        })
          .unwrap()
          .then(({ success, error, data }: { success: boolean; error: string; data: Listing }) => {
            if (success) {
              if (data.gmbStatus === 'pendingDirectVerification') {
                goToStep?.(3);
              }
              if (values.selectedOption === 'SCHEDULE') {
                setListing?.(data);
                if (data.gmbStatus === 'pendingExternalVerification') {
                  setShowPendingVerification?.(true);
                }
                setVerificationOption?.(values.selectedOption);
                goToStep?.(6);
              }
              if (
                values.selectedOption === 'NOT_NOW' ||
                values.selectedOption === 'SUPPORT_EMAIL'
              ) {
                if (data.gmbStatus === 'pendingExternalVerification') {
                  setListing?.(data);
                  setShowPendingVerification?.(true);
                }
                goToStep?.(4);
              }
            } else {
              console.error(error);
            }
          });
      }

      // await listingSvc
      //   .startVerification(listingId as string, values.selectedOption)
      //   .then(({ success, error, data }: { success: boolean; error: string; data: Listing }) => {
      //     if (success) {
      //       if (data.gmbStatus === 'pendingDirectVerification') {
      //         goToStep?.(3);
      //       }
      //       if (values.selectedOption === 'SCHEDULE') {
      //         setListing?.(data);
      //         if (data.gmbStatus === 'pendingExternalVerification') {
      //           setShowPendingVerification?.(true);
      //         }
      //         setVerificationOption?.(values.selectedOption);
      //         goToStep?.(6);
      //       }
      //       if (values.selectedOption === 'NOT_NOW' || values.selectedOption === 'SUPPORT_EMAIL') {
      //         if (data.gmbStatus === 'pendingExternalVerification') {
      //           setListing?.(data);
      //           setShowPendingVerification?.(true);
      //         }
      //         goToStep?.(4);
      //       }
      //     } else {
      //       console.error(error);
      //     }
      //   });
    } catch (errors) {
      console.log('Error!', errors);
    }
  };

  return (
    <Center
      className="fttf-business-verification"
      flexDirection="column"
      pb={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box maxW="xl">
        <Heading as="h1" size="hs-xl">
          Verify Your Business
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          To publish and manage your listing on Google My Business and the Vastly Directory for
          free, you’ll need to verify your connection with this business.
        </Heading>
      </Box>

      <Box
        as="form"
        className="gmb-verificationOptions__form"
        maxWidth={480}
        width="full"
        textAlign="left"
        mt={[8, 10]}
      >
        <Heading size="hs-sm" textAlign="left" mb={6}>
          {t('gmbProfileFlow.verificationOptions.verifyOption')}
        </Heading>
        {loading ? (
          <Box my={[7]}>
            <AppSpinner />
          </Box>
        ) : (
          <Controller
            name="selectedOption"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                className="blp-verificationBusiness__selectedOptionRadio"
                data-test="blp-verificationBusiness__selectedOptionRadio"
              >
                <Stack spacing={3} direction="column" textAlign="left">
                  {permittedVerificationOptions &&
                    allVerificationOptions
                      .filter(fullOption =>
                        permittedVerificationOptions?.includes(fullOption.value),
                      )
                      .map(option => {
                        return (
                          <Radio key={option.value} value={option.value}>
                            {t(`gmbProfileFlow.verificationOptions.options.${option.value}` as any)}
                          </Radio>
                        );
                      })}
                </Stack>
              </RadioGroup>
            )}
          />
        )}
      </Box>
      <Box textAlign="center" mt={[8, 12]}>
        <ButtonGroup size="lg" spacing={6}>
          <Button
            onClick={() => {
              previousStep?.();
            }}
            className="blp-verificationBusiness__backButton"
            data-test="blp-verificationBusiness__backButton"
            variant="outline"
          >
            {t('common.buttons.back')}
          </Button>

          <Button
            onClick={handleSubmit(onSubmit)}
            className="blp-verificationBusiness__submitButton"
            data-test="blp-verificationBusiness__submitButton"
            isLoading={isSubmitting}
            isDisabled={!isValid}
          >
            {t('common.buttons.next')}
          </Button>
        </ButtonGroup>
      </Box>
    </Center>
  );
};
