import React, { useState, useEffect } from 'react';
import { Text, Box, Button, Center, Heading } from '@companydotcom/potion';
import { platformHelpers, companyHelpers } from '@companydotcom/helpers';
import { CircleExclamationIcon } from '@companydotcom/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resendEmailActivation } from '../../../services/auth/old-api/auth-svc';
import { VerifyEmailPage } from '../verify-email/verify-email-page';
import { useResetPasswordEmailMutation } from '../../../services/enrollment/enrollment-api';
export const Reverify = () => {
  const navigate = useNavigate();
  const [resetPasswordEmail] = useResetPasswordEmailMutation();
  const [isReset, setIsReset] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isResent, setIsResent] = useState(false);
  const { t } = useTranslation();

  const location = useLocation();
  const { hash } = location;
  const email = companyHelpers.getValueFromString(hash, 'email');
  const emailSource = companyHelpers.getValueFromString(hash, 'source');
  const cpFirstTime = companyHelpers.getValueFromString(hash, 'cpFirstTime');
  const formattedEmail = email?.replace(' ', '+');

  useEffect(() => {
    // given url dont have the source coming from url and not company
    // for ipayment url includes paysafe
    if (
      emailSource &&
      !window.location.host.includes(emailSource) &&
      emailSource !== 'company' &&
      !(emailSource === 'ipayment' && window.location.host.includes('paysafe'))
    ) {
      const url = window.location.href.split('?');

      const redirectURL = `${platformHelpers.getRedirectURL(emailSource, url[0])}?${url[1]}`;

      window.location.replace(redirectURL);
    }
  }, [emailSource]);

  const handleReverify = async () => {
    try {
      if (cpFirstTime === '1') {
        await resendEmailActivation(formattedEmail as string);
        setIsEmailSent(true);
      } else {
        await resetPasswordEmail({
          email: formattedEmail as string,
          source: emailSource as string,
        });
        setIsReset(true);
      }
    } catch (e) {
      console.log(e);
      navigate('/logout');
    }
  };

  const handleBackToLogin = () => {
    const newRedirectionURl = platformHelpers.getRedirectURL(emailSource, window.location.origin);
    navigate(`${newRedirectionURl}/login`, { replace: true });
  };

  const resendVerification = async () => {
    if (!isSending && email) {
      setIsSending(true);
      await resendEmailActivation(email);
      setIsResent(true);
      setIsSending(false);
    }
  };

  return isEmailSent ? (
    <Center
      textAlign="center"
      flexDirection="column"
      layerStyle="formContainer"
      maxWidth={704}
      width="full"
      mx="auto"
    >
      <VerifyEmailPage
        isSending={isSending}
        isResent={isResent}
        email={formattedEmail}
        resendVerification={resendVerification}
      />
    </Center>
  ) : (
    <Center
      textAlign="center"
      flexDirection="column"
      layerStyle="formContainer"
      maxWidth={704}
      width="full"
      mx="auto"
    >
      {isReset ? (
        <Box mt={[12, 16]}>
          <Heading size="hs-2xl">{t('subpage.reverify.heading')}</Heading>
          <Text textStyle="md" maxW="md" mt={8}>
            {t('subpage.reverify.subheading')}
          </Text>
        </Box>
      ) : (
        <Box mt={[12, 16]}>
          <CircleExclamationIcon />
          <Heading size="hs-2xl" mt={8}>
            {t('subpage.reverify.expiredLink')}
          </Heading>
          <Text textStyle="md" maxW="md" mt={8}>
            {t('subpage.reverify.clickBelow')}
          </Text>
        </Box>
      )}
      <Box mt={10} mb={[12, 16]} mx="auto">
        <Button
          size="lg"
          variant="outline"
          _hover={{
            textDecoration: 'none',
          }}
          onClick={isReset ? () => handleBackToLogin() : () => handleReverify()}
        >
          {isReset ? t('subpage.reverify.backToLogin') : t('subpage.reverify.resendEmail')}
        </Button>
      </Box>
    </Center>
  );
};
