import React, { useEffect, useState } from 'react';
import { Text, Box, Button, Heading, Center, Link } from '@companydotcom/potion';
import { useNavigate } from 'react-router-dom';
import { Listing } from '@companydotcom/types';
import { CheckCircleIcon } from '@companydotcom/ui';

export interface BLPCongratsProps {
  setFirstTimeTileFlow?: (arg: boolean) => void;
  isSecondChanceGmb?: boolean;
  listing?: Listing;
  verificationOption?: string;
}

export const BLPCongrats: React.FC<BLPCongratsProps> = props => {
  const { setFirstTimeTileFlow, isSecondChanceGmb, listing, verificationOption } = props;
  const [successType, setSuccessType] = useState('default');
  const navigate = useNavigate();

  const successConfig: any = {
    default: {
      showCheck: true,
      header: 'Congrats!',
      sub1: 'Your business is verified in Google My Business and the Vastly directory. See your listing on Google My Business.',
      sub2: 'You can make changes any time to your Business Listings Profile and sync them to your Google and Vastly listings. See how your new Business Listings Profile looks.',
    },
    email: {
      showCheck: true,
      header: 'Your Profile Was Created Successfully!',
      sub1: 'Your verification postcard will be sent to the business address listed in your profile. One of our customer success representatives will follow up with you via email to explain what’s next.',
      sub2: 'You can make changes any time to your Business Listings Profile and sync them to your Google and Vastly listings. See how your new Business Listings Profile looks.',
    },
    blpOnly: {
      header: 'Congrats!',
      sub1: 'Your business is verified in the Vastly directory.',
      anchor: {
        text: 'See your listing.',
        getSrc: (list: any = {}) => list.listingUrl,
      },
      sub2: 'You can make changes any time to your Business Listings Profile and sync them to your Google and Vastly listings. See how your new Business Listings Profile looks.',
    },
    secondChanceVerified: {
      header: 'Congrats!',
      sub1: 'Your business is verified in Google My Business',
      sub2: 'You can make changes any time to your Business Listings Profile and sync them to your Google and Vastly listings. See how your new Business Listings Profile looks.',
    },
    secondChanceInProgress: {
      header: 'Congrats!',
      sub1: 'Your business is in the process of being verified in Google My Business.',
      sub2: 'You can make changes any time to your Business Listings Profile and sync them to your Google and Vastly listings. See how your new Business Listings Profile looks.',
    },
  };

  useEffect(() => {
    if (isSecondChanceGmb) {
      if (listing?.gmbStatus === 'verified') {
        setSuccessType('secondChanceVerified');
      } else if (verificationOption === 'SUPPORT_EMAIL') {
        setSuccessType('email');
      } else {
        setSuccessType('secondChanceInProgress');
      }
    } else if (listing?.gmbStatus === 'optOut') {
      setSuccessType('blpOnly');
    } else if (verificationOption === 'SUPPORT_EMAIL') {
      setSuccessType('email');
    } else {
      setSuccessType('default');
    }
  }, [isSecondChanceGmb, listing?.gmbStatus, verificationOption]);

  return (
    <Center
      className="fttf-success-screen"
      flexDirection="column"
      pb={[8, 12]}
      px={4}
      textAlign="center"
    >
      <Box maxW="xl">
        {successConfig[successType].showCheck ? <CheckCircleIcon mb={[4]} /> : null}
        <Heading as="h1" size="hs-xl">
          {successConfig[successType].header}
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          {successConfig[successType].sub1}
          {successConfig[successType].anchor && successConfig[successType].anchor.getSrc(listing) && (
            <Link isExternal href={successConfig[successType].anchor.getSrc(listing)}>
              {' '}
              {successConfig[successType].anchor.text}
            </Link>
          )}
        </Heading>
        <Text textStyle="md" mt={4} mb={[5]}>
          {successConfig[successType].sub2}
        </Text>
        {successType === 'blpOnly' ? (
          <Button size="lg" mt={[13]} onClick={() => setFirstTimeTileFlow?.(false)}>
            See My Business Listings Profile
          </Button>
        ) : (
          <Button
            size="lg"
            mt={[13]}
            onClick={() => {
              setFirstTimeTileFlow?.(false);
              navigate('/');
            }}
          >
            Back To dashboard
          </Button>
        )}
      </Box>
    </Center>
  );
};
