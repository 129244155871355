import React, { useState, useEffect } from 'react';
import {
  Box,
  Link,
  Text,
  Button,
  Heading,
  Alert,
  Center,
  RadioGroup,
  Radio,
  Stack,
  Icon,
  useBoolean,
  VStack,
  HStack,
  AlertTitle,
  AlertDescription,
} from '@companydotcom/potion';
import { parsePhoneNumber } from 'libphonenumber-js';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/pro-solid-svg-icons';
import {
  SelectField,
  InputField,
  ReactSelectFieldAsync,
  PhoneNumberInputField,
  useGetCurrentlySelectedCountry,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { companyHelpers } from '@companydotcom/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { User, Listing, EmptyObject, Maybe } from '@companydotcom/types';
import stateOptions from '../../utils/state-options';
import { useLazyGetGmbCategoryAutocompleteQuery } from '../../../../services/search/search-api';
import {
  useCreateListingMutation,
  useLazyGetLocationAutocompleteResultsQuery,
  useUpdateListingMutation,
} from '../../../../services/listings/listing-api';
import { useAwaitableFacade } from '../../../../hooks';
import yup from '../../../../lib/yup';
// import { getGmbCategoryAutocomplete } from '../../../../services/search/old-api/search-svc';

const getBasicBusinessInformationSchema = () =>
  yup.object().shape({
    address: yup.string().required('Please enter number and street only'),
    businessCategory: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string().required('Please select a category for your business'),
        fullName: yup.string(),
        publisher: yup.string(),
      })
      .required('Please select a category for your business'),
    businessName: yup.string().required('Please enter your business name'),
    city: yup.string().required('Please enter your city'),
    phone: yup.string().phone().required('Please enter your phone number').nullable(),
    serviceArea: yup.array().when('visitable', {
      is: (val: any) => {
        return val === 'notVisitable';
      },
      then: yup.array().required('Must select service area'),
    }),
    state: yup.string().required('Please enter a state'),
    visitable: yup
      .string()
      .oneOf(['visitable', 'notVisitable'])
      .required('Please select whether your business is visitable or not'),

    zipCode: yup
      .string()
      .required('Please enter your zip code')
      .test('zip code', 'Please enter a valid zip code', val =>
        companyHelpers.validateZipCode(val),
      ),
  });

interface BasicBusinessInformationProps {
  user?: User;
  setListingId?: (arg: string) => void;
  setListingUrl?: (arg?: Maybe<string>) => void;
  setListing?: (arg: Listing) => void;
  listing?: Listing | EmptyObject;
  isSecondChanceGmb?: boolean;
  // listingSvc?: any;
  goToStep?: (arg: string | number) => void;
}

export const BasicBusinessInformation: React.FC<BasicBusinessInformationProps> = props => {
  const {
    user,
    setListingId,
    setListingUrl,
    setListing,
    listing = {},
    isSecondChanceGmb,
    // listingSvc,
    goToStep,
  } = props;
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();

  const [addAreas, setAddAreas] = useBoolean(false);
  const [duplicateListing, setDuplicateListing] = useState(false);
  const { t } = useTranslation();
  const [updatedListing] = useUpdateListingMutation();
  const [createListing] = useCreateListingMutation();
  const [getGmbCategoryAutocomplete] = useLazyGetGmbCategoryAutocompleteQuery();
  const [getLocationAutocompleteResults] = useLazyGetLocationAutocompleteResultsQuery();
  const snsInterface = useAwaitableFacade(user, 'listing');

  const defaultValues: FieldValues = {
    businessName: listing?.name || user?.account?.businessPrimary?.name || '',
    address: listing?.address || user?.account?.businessPrimary?.address?.addressLine1 || '',
    addressExtra: user?.account?.businessPrimary?.address?.addressLine2 || '',
    zipCode: listing?.zip || user?.account?.businessPrimary?.address?.zipCode || '',
    city: listing?.city || user?.account?.businessPrimary?.address?.city || '',
    state: listing?.state || user?.account?.businessPrimary?.address?.state || '',
    businessCategory:
      (listing?.categories || user?.account?.businessPrimary?.categories || []).filter(
        cat => cat?.publisher === 'GMB',
      )?.[0] || undefined,
    businessDescription: listing?.description || '',
    phone: listing?.phones?.find?.(x => x?.type === 'MAIN')?.number
      ? parsePhoneNumber(
          listing?.phones?.find?.(x => x?.type === 'MAIN')?.number as string,
          country,
        ).number
      : user?.phone
      ? parsePhoneNumber(user.phone, country).number
      : '',

    businessWebsite: listing?.urls?.find?.(x => x?.type === 'WEBSITE')?.url || '',
    visitable: listing?.hideAddress ? 'notVisitable' : 'visitable',
    gmbStatus: listing?.gmbStatus || 'optIn',
    serviceArea: listing?.serviceArea?.places?.placeInfos || [],
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getBasicBusinessInformationSchema()),
    defaultValues,
  });

  const watchVisitable = watch('visitable');

  useEffect(() => {
    // Revalidate the serviceArea field every time the visitable radio button is pressed
    if (watchVisitable) {
      trigger('serviceArea');
    }
  }, [trigger, watchVisitable]);

  const visitableRadioOptions = [
    {
      label: t('gmbProfileFlow.stepTwo.inputs.visitableRadioOptions.visitable'),
      value: 'visitable',
    },
    {
      label: t('gmbProfileFlow.stepTwo.inputs.visitableRadioOptions.notVisitable'),
      value: 'notVisitable',
    },
  ];

  const gmbStatusOptions = [
    {
      label: t('gmbProfileFlow.stepTwo.inputs.gmbStatus.both'),
      value: 'optIn',
    },
    {
      label: t('gmbProfileFlow.stepTwo.inputs.gmbStatus.directoryOnly'),
      value: 'optOut',
    },
    {
      label: t('gmbProfileFlow.stepTwo.inputs.gmbStatus.none'),
      value: 'suppress',
    },
  ];

  const categoryOptions = async (value: any) => {
    const categories = await getGmbCategoryAutocomplete({ searchTerm: value })
      .unwrap()
      .catch(error => console.error('Rejected! ', error));
    return categories;
  };

  const locationOptions = async (value: any) => {
    // const locations = await listingSvc.getLocationAutocompleteResults(value);
    const locations = await getLocationAutocompleteResults({ searchTerm: value })
      .unwrap()
      .catch(error => console.error('Rejected! ', error));
    return locations;
  };

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      const listingInput: any = {
        accountId: user?.accountId,
        hideAddress: values.visitable === 'notVisitable',
        name: values.businessName,
        address: values.address,
        zip: values.zipCode,
        country: 'US',
        categories: [
          {
            fullName: values.businessCategory?.fullName,
            id: values.businessCategory?.id,
            name: values.businessCategory?.name,
            publisher: values.businessCategory?.publisher,
          }, // having to drop _typename from this
        ],
        status: 'CLAIMED',
        phones: [
          {
            type: 'MAIN',
            number: getFormattedPhoneNumber(values.phone, country ?? 'US'),
          },
        ],

        gmbStatus: isSecondChanceGmb
          ? 'optIn'
          : values.gmbStatus === 'suppress'
          ? 'optOut'
          : values.gmbStatus,
        suppress: values.gmbStatus === 'suppress',
        source: 'companydotcom',
        city: values.city,
        state: values.state,
        description: values.businessDescription,
        urls: [
          {
            url: values.businessWebsite,

            type: 'WEBSITE',
          },
        ],
        serviceArea:
          values.serviceArea && values.serviceArea.length
            ? {
                businessType:
                  values.visitable === 'notVisitable'
                    ? 'CUSTOMER_LOCATION_ONLY'
                    : 'CUSTOMER_AND_BUSINESS_LOCATION',
                places: {
                  placeInfos: values.serviceArea?.map(({ name, placeId }: any) => ({
                    name,
                    placeId,
                  })),
                },
              }
            : {},
      };

      if (user) {
        if (listing && listing.id) {
          delete listingInput.source;
          delete listingInput.status;
          listingInput.id = listing.id;

          await updatedListing({ listingInput, snsInterface })
            .unwrap()
            .then(updatedListing => {
              if (updatedListing) {
                setListing?.(updatedListing);
              }
              switch (updatedListing?.gmbStatus) {
                case 'optOut':
                  goToStep?.('blp-congrats');
                  break;
                case 'optIn':
                  goToStep?.('business-verification');
                  break;
                case 'externalClaim':
                  goToStep?.('gmb-duplicate');
                  break;
                case 'locationCreationFailed':
                default:
                  goToStep?.('blp-error');
                  break;
              }
            });

          // await listingSvc
          //   .updateListing(listingInput)
          //   .then(({ data: updatedListing }: { success: boolean; error: string; data: Listing }) => {
          //     if (updatedListing) {
          //       setListing?.(updatedListing);
          //     }

          //     switch (updatedListing?.gmbStatus) {
          //       case 'optOut':
          //         goToStep?.('blp-congrats');
          //         break;
          //       case 'optIn':
          //         goToStep?.('business-verification');
          //         break;
          //       case 'externalClaim':
          //         goToStep?.('gmb-duplicate');
          //         break;
          //       case 'locationCreationFailed':
          //       default:
          //         goToStep?.('blp-error');
          //         break;
          //     }
          //   });
        } else {
          await createListing({ listingInput, snsInterface })
            .unwrap()
            .then(
              ({
                success,
                error,
                data: newListing,
              }: {
                success: boolean;
                error: string;
                data: Listing;
              }) => {
                if (error === 'Directory Duplicate') {
                  setDuplicateListing(true);
                }
                if (success) {
                  setListingId?.(newListing?.id ?? '');
                  setListingUrl?.(newListing?.listingUrl);
                  setListing?.(newListing);

                  switch (newListing?.gmbStatus) {
                    case 'optOut':
                      goToStep?.('blp-congrats');
                      break;
                    case 'optIn':
                      goToStep?.('business-verification');
                      break;
                    case 'externalClaim':
                      goToStep?.('gmb-duplicate');
                      break;
                    case 'locationCreationFailed':
                    default:
                      goToStep?.('blp-error');
                      break;
                  }
                }
              },
            )
            .catch(err => console.error('Error creating listing inside basic-business ', err));

          // await listingSvc
          //   .createListing(listingInput)
          //   .then(
          //     ({
          //       success,
          //       error,
          //       data: newListing,
          //     }: {
          //       success: boolean;
          //       error: string;
          //       data: Listing;
          //     }) => {
          //       if (error === 'Directory Duplicate') {
          //         setDuplicateListing(true);
          //       }
          //       if (success) {
          //         setListingId?.(newListing?.id);
          //         setListingUrl?.(newListing?.listingUrl);
          //         setListing?.(newListing);

          //         switch (newListing?.gmbStatus) {
          //           case 'optOut':
          //             goToStep?.('blp-congrats');
          //             break;
          //           case 'optIn':
          //             goToStep?.('business-verification');
          //             break;
          //           case 'externalClaim':
          //             goToStep?.('gmb-duplicate');
          //             break;
          //           case 'locationCreationFailed':
          //           default:
          //             goToStep?.('blp-error');
          //             break;
          //         }
          //       }
          //     },
          //   )
          //   .catch((error: any) => console.log(error));
        }
      }
    } catch (err) {
      console.log('Error updating listing', err);
    }
  };

  return (
    <Center
      className="fttf-basic-business-information"
      flexDirection="column"
      pb={[12, null, 16]}
      width="full"
      px={4}
    >
      <Box textAlign="center" mb={14} maxW="lg">
        <Heading as="h1" size="hs-xl">
          Basic Business Information
        </Heading>
        <Heading as="h2" size="hs-md" mt={[5]}>
          Add your basic business information so that customers can find your business in local
          searches.
        </Heading>
        <Text textStyle="sm" mt={4}>
          All fields are required unless marked optional
        </Text>
      </Box>

      <Center flexDirection="column" maxWidth={475} width="full">
        <Box textAlign="left" width="full">
          <Heading size="hs-md" mb={5}>
            1. Business Name and Address
          </Heading>
          <VStack spacing={6} width="full">
            <InputField
              register={register}
              name="businessName"
              autoFocus
              label="Business Name"
              errors={errors}
            />
            <InputField
              register={register}
              name="address"
              label="Business Street Address"
              errors={errors}
              helperText="Please enter number and street only"
            />
            <InputField register={register} name="addressExtra" errors={errors} />
            <InputField
              register={register}
              name="zipCode"
              type="number"
              min="0"
              label="Business ZIP Code"
              errors={errors}
            />
            <HStack alignItems="flex-start">
              <InputField register={register} name="city" label="City" errors={errors} />
              <SelectField
                register={register}
                name="state"
                label={t('common.inputs.state.label')}
                errors={errors}
              >
                {stateOptions.map(opt => (
                  <option value={opt.value} key={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </SelectField>
            </HStack>
          </VStack>

          <Box mt={7} width="full">
            <Text textStyle="lg" fontWeight="medium" mb={4}>
              Can customers visit this location?
            </Text>
            <Controller
              name="visitable"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  className="blp-basicBusinessInformation__visitableRadio"
                  data-test="blp-basicBusinessInformation__visitableRadio"
                >
                  <Stack spacing={2} direction="column">
                    {visitableRadioOptions.map(option => (
                      <Radio key={option.value} value={option.value}>
                        {option.label}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
            />
            <Button
              className="blp-completeBusinessProfile__setAddAreasButton"
              data-test="blp-basicBusinessInformation__setAddAreasButton"
              onClick={setAddAreas.toggle}
              display="inline-flex"
              alignItems="center"
              variant="unstyled"
              borderRadius="none"
              size="sm"
              fontSize={['sm', 'md']}
              py={0}
              mt={7}
              mb={addAreas || watchVisitable === 'notVisitable' ? 1 : 6}
              leftIcon={
                <Icon as={FontAwesomeIcon} icon={addAreas ? faCircleMinus : faCirclePlus} />
              }
            >
              {t('gmbProfileFlow.stepTwo.areasServed', {
                watchVisitable: watchVisitable === 'notVisitable' ? '' : ' (optional)',
              })}
            </Button>
            {addAreas || watchVisitable === 'notVisitable' ? (
              <ReactSelectFieldAsync
                isMulti
                className="blp-basicBusinessInformation__serviceAreaField"
                data-test="blp-basicBusinessInformation__serviceAreaField"
                control={control}
                name="serviceArea"
                loadOptions={locationOptions}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.placeId}
                errors={errors}
                formControlStyles={{ marginLeft: 5, marginBottom: 6, width: 'auto' }}
              />
            ) : null}
          </Box>
        </Box>

        <Box textAlign="left" width="full" mt={12}>
          <Heading size="hs-md" mb={5}>
            2. Business Description
          </Heading>
          <VStack spacing={6} width="full">
            <ReactSelectFieldAsync
              className="blp-basicBusinessInformation__businessCategoryField"
              data-test="blp-basicBusinessInformation__businessCategoryField"
              control={control}
              errors={errors}
              name="businessCategory"
              label={t('common.inputs.businessCategory.label')}
              loadOptions={categoryOptions}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => (option.id === null ? undefined : option.id)}
            />

            <InputField
              register={register}
              name="businessDescription"
              label="Description of the Business (optional)"
              errors={errors}
            />
          </VStack>
        </Box>
        <Box textAlign="left" width="full" mt={12}>
          <Heading size="hs-md" mb={5}>
            3. Contact Information
          </Heading>
          <VStack spacing={6} width="full">
            <PhoneNumberInputField
              name="phone"
              label="Business Phone Number"
              className="form-childUserProfileForm__phoneField"
              data-test="form-childUserProfileForm__phoneField"
              control={control}
              country={country}
              onCountryChange={onCountryChange}
              errors={errors}
            />

            <InputField
              register={register}
              name="businessWebsite"
              label="Website (optional)"
              errors={errors}
            />
          </VStack>
        </Box>
        <VStack
          textAlign="left"
          width="full"
          mt={12}
          display={isSecondChanceGmb ? 'none' : 'inherit'}
        >
          <Heading size="hs-md" mb={5}>
            4. Where would you like your business listing sent?
          </Heading>
          <Controller
            name="gmbStatus"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                width="full"
                className="gmb-completeBusinessProfile__gmbStatusRadio"
                data-test="gmb-completeBusinessProfile__gmbStatusRadio"
              >
                <Stack spacing={2} direction="column">
                  {gmbStatusOptions.map(option => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          />
        </VStack>
      </Center>

      {duplicateListing && (
        <Alert
          status="warning"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width="full"
          my={8}
        >
          <AlertTitle>Possible Duplicate Listing:</AlertTitle>
          <AlertDescription>
            Looks like your business may already be listing in the Vastly directory.
          </AlertDescription>
          <AlertDescription>
            If you believe this to be an error please{' '}
            <Link as={NavLink} to="/help">
              contact support.
            </Link>
          </AlertDescription>
        </Alert>
      )}
      <Box mt={12}>
        <Button
          size="lg"
          isDisabled={!isValid}
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          {t('common.buttons.next')}
        </Button>
      </Box>
    </Center>
  );
};
