import { useState } from 'react';
import {
  Flex,
  Box,
  Text,
  Button,
  Heading,
  AccordionButton,
  AccordionPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useBreakpointValue,
  VStack,
  Alert,
  AlertTitle,
  AlertDescription,
} from '@companydotcom/potion';
import {
  InputField,
  PhoneNumberInputField,
  useGetCurrentlySelectedCountry,
  getFormattedPhoneNumber,
} from '@companydotcom/ui';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FieldValues, useForm } from 'react-hook-form';
import { ExtendedSubscriptionProduct, User } from '@companydotcom/types';
import { parsePhoneNumber } from 'libphonenumber-js';
import { isEmpty } from 'lodash';
import { ensure } from '@companydotcom/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation, Trans } from 'react-i18next';
import { useUserProfileContext } from '../../../providers/user-profile-context';
import { formatProductName } from '../../../features/add-new-user-flow';
import { ChildUserAccordionTitle } from './child-user-accordion-title';
import { ActivationModal } from './activation-modal';
import { SuspendDeleteModal } from './suspend-delete-modal';
import { ChooseUserProduct } from '../choose-user-product';
import { PhotoEditor } from '..';
import {
  toggleChildUserAccess,
  releaseChildUserSeat,
  getChildEmailProductStatus,
  getDefaultToggleStateForChild,
  determineIfProductDisabled,
} from '../../../pages/user-profile/utils';
import { useToast } from '../../../hooks';
import {
  useGetGlobalUserQuery,
  useUpdateUserMutation,
  useLazyGetUserTilesQuery,
  useGetUserLiteQuery,
  useGetUserTileStatesQuery,
} from '../../../services/user/user-api';
import { useLazyPublishTileEventQuery } from '../../../services/event/event-api';
import { useResendEmailActivationMutation } from '../../../services/auth/auth-api';
import yup from '../../../lib/yup';
// import { resendEmailActivation } from '../../../services/auth/old-api/auth-svc';

const ChildUserAccordionSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('Please enter your first name'),
    lastName: yup.string().required('Please enter your last name'),
    phone: yup.string().phone().nullable(),
  });

export interface ChildUserAccordionProps {
  childUser: User;
  avatarColor?: string;
  isParentEmailProductActive?: boolean;
  locale?: string;
  isExpanded?: boolean;
}

export const ChildUserAccordion = ({
  childUser,
  avatarColor,
  isParentEmailProductActive,
  locale,
  isExpanded,
}: ChildUserAccordionProps) => {
  const { country, onCountryChange } = useGetCurrentlySelectedCountry();

  const [updateUser] = useUpdateUserMutation();
  const [getUserTiles] = useLazyGetUserTilesQuery();
  const [publishTileEvent] = useLazyPublishTileEventQuery();
  const [resendEmailActivation] = useResendEmailActivationMutation();
  const { userProfileState, dispatchUserProfileState } = useUserProfileContext();
  const { data: globalUser } = useGetGlobalUserQuery();
  const { data: userLite } = useGetUserLiteQuery(
    childUser ? { userId: childUser.userId } : skipToken,
  );
  const { data: userTileState } = useGetUserTileStatesQuery(
    childUser ? { userId: childUser.userId } : skipToken,
  );

  const { isOpen: isEditorOpen, onOpen: setEditorOpen, onClose: setEditorClose } = useDisclosure();
  const {
    isOpen: isActivationOpen,
    onOpen: setIsActivationOpen,
    onClose: setIsActivationClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: setDeleteConfirmationOpen,
    onClose: setDeleteConfirmationClose,
  } = useDisclosure();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [childUserAvatar, setChildUserAvatar] = useState(childUser?.avatar);
  const [childUserFirstname, setChildUserFirstname] = useState(childUser?.firstName);
  const [childUserLastname, setChildUserLastname] = useState(childUser?.lastName);
  const parsedChildProducts = childUser?.products ? JSON.parse(childUser?.products) : [];
  const [actionLoading, setActionLoading] = useState<
    'reactivating' | 'deleting' | 'activating' | 'suspending' | undefined
  >(undefined);

  const [childEmailProductStatus, setChildEmailProductStatus] = useState(
    getChildEmailProductStatus(childUser.products && JSON.parse(childUser.products)),
  );

  const defaultProductToggleState = getDefaultToggleStateForChild(
    userProfileState?.eligibleChildProducts,
    parsedChildProducts,
    userProfileState?.emailSeatCount?.remainingEntitledSeats,
    isParentEmailProductActive,
    childEmailProductStatus,
  );
  const [childProductValues, setChildProductValues] = useState<{ [key: string]: boolean }>(
    defaultProductToggleState,
  );

  const defaultValues: FieldValues = {
    firstName: childUser?.firstName || '',
    lastName: childUser?.lastName || '',
    phone: childUser?.phone ? parsePhoneNumber(childUser?.phone, country).number : '',
    personalEmail: childUser?.email || '',
    companyEmail: `${childUser?.mailbox}@${childUser.mailboxFQDN}` || '',
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isResendEmailLoading, setResendEmailLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ChildUserAccordionSchema()),
    defaultValues,
    context: { country },
  });
  const { t } = useTranslation();

  const toast = useToast();

  const handleToggleClick = (clickedValue: any) => {
    setChildProductValues(prevState => ({
      ...prevState,
      [clickedValue]: !childProductValues?.[clickedValue],
    }));

    setValue(clickedValue, !childProductValues?.[clickedValue], { shouldDirty: true });
  };

  const handleResendEmail = async (email: string) => {
    setResendEmailLoading(true);
    const res = await resendEmailActivation({ email }).unwrap();

    if (res && res.message === 'Email sent successfully') {
      setIsActivationOpen();
    }
    setResendEmailLoading(false);
  };

  // Ensures the toggle goes back to its original state of the user doesn't complete flow
  const handleToggleReset = () => {
    dispatchUserProfileState?.({ type: 'UPDATE_STATE', payload: { productIntention: undefined } });
    setChildProductValues(defaultProductToggleState);
  };

  // Determines modal handling for pruchasing, suspensing, deletion, and reactivation
  const handleModalOpen = (
    intention: 'purchase' | 'manage',
    childUser: User,
    product: ExtendedSubscriptionProduct,
  ) => {
    const shouldGrantSeat =
      product?.slug &&
      !defaultProductToggleState[product.slug] &&
      childEmailProductStatus !== 'active';

    dispatchUserProfileState?.({
      type: 'UPDATE_STATE',
      payload: {
        existingChildUser: childUser,
        chosenProducts: {
          ...childProductValues,
          [product.slug]: !childProductValues[product.slug],
        },
        newUserInformation: {
          firstName: childUser.firstName || '',
          lastName: childUser.lastName || '',
          personalEmail: childUser.email || '',
        },
        productIntention: {
          intention: shouldGrantSeat ? 'grant_access' : 'suspend_access',
          product: ensure(
            userProfileState?.eligibleChildProducts?.find(p => p.slug === product.slug),
          ),
        },
      },
    });

    if ((intention === 'purchase' || shouldGrantSeat) && childUser.userId) {
      dispatchUserProfileState?.({
        type: 'TOGGLE_NEW_USER_MODAL',
        payload: {
          initialNewUserStep:
            intention === 'purchase' ? 'add-additional-email-users' : 'create-a-username',
          onModalClose: handleToggleReset,
          onManageSeatSuccess: () => {
            setChildEmailProductStatus('active');
            dispatchUserProfileState?.({
              type: 'UPDATE_PRODUCT_STATUS',
              payload: {
                userId: childUser.userId as string,
                productId: product.productId,
                status: 'active',
              },
            });
            handleToggleClick(product.slug);
            toast({
              description: t('miop.userProfile.childUser.snackbar.emailActivated'),
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
            dispatchUserProfileState({ type: 'RESET_ALL_USER_PROFILE_STATE' });
          },
          onPaymentSuccessStep: intention === 'purchase' ? 'create-a-username' : undefined,
        },
      });
    } else {
      setDeleteConfirmationOpen();
    }
  };

  const handleSuspendOrDeleteSeat = async (
    action: 'suspend' | 'delete',
    childUser: User,
    product?: ExtendedSubscriptionProduct,
  ) => {
    if (userProfileState && childUser.userId && product) {
      try {
        setActionLoading(action === 'suspend' ? 'suspending' : 'deleting');
        setButtonLoading(true);

        const { data: userTiles } = await getUserTiles({ userId: childUser?.userId, locale });

        const tileIdFromProductId = userTiles?.find(
          tile => tile.productId === product.productId,
        )?.tileId;

        if (tileIdFromProductId && globalUser?.account) {
          const toggleAccess =
            action === 'suspend'
              ? await toggleChildUserAccess(
                  childUser?.userId,
                  product,
                  globalUser?.account,
                  tileIdFromProductId,
                  false,
                  publishTileEvent,
                  userLite,
                  userTileState,
                )
              : await releaseChildUserSeat(
                  childUser?.userId,
                  product,
                  globalUser?.account,
                  tileIdFromProductId,
                  publishTileEvent,
                  userLite,
                );

          if (toggleAccess?.success) {
            setActionLoading(undefined);
            setDeleteConfirmationClose();
            dispatchUserProfileState?.({
              type: 'UPDATE_STATE',
              payload: { productIntention: undefined },
            });
            dispatchUserProfileState?.({
              type: 'UPDATE_PRODUCT_STATUS',
              payload: {
                userId: childUser.userId,
                productId: product.productId,
                status: action === 'suspend' ? 'disabled' : 'inactive',
              },
            });

            if (action === 'delete') {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              product.slug && handleToggleClick(product.slug);
              dispatchUserProfileState({
                type: 'INCREASE_AVAILABLE_SEAT_COUNT',
                payload: { qty: 1, options: 'add-remaining-only' },
              });
            }

            toast({
              description: t(
                action === 'suspend'
                  ? 'miop.userProfile.childUser.snackbar.emailSuspended'
                  : 'miop.userProfile.childUser.snackbar.emailDeleted',
              ),
              status: 'success',
              duration: 9000,
              isClosable: true,
            });

            setActionLoading(undefined);
            setChildEmailProductStatus(action === 'suspend' ? 'disabled' : 'inactive');
            setButtonLoading(false);
          } else {
            setActionLoading(undefined);
            setDeleteConfirmationClose();
            dispatchUserProfileState?.({
              type: 'UPDATE_STATE',
              payload: { productIntention: undefined },
            });
            setButtonLoading(false);
          }
        }
      } catch (err) {
        setActionLoading(undefined);
        setButtonLoading(false);
      }
    }
  };

  // Sent from a suspended/disabled seat and user wants to reactivate the account
  const handleReactivateSeat = async (childUser: User, product: ExtendedSubscriptionProduct) => {
    try {
      setActionLoading('reactivating');
      if (product && childUser.userId && globalUser?.account) {
        const { data: userTiles } = await getUserTiles({ userId: childUser?.userId, locale });
        const tileIdFromProductId = userTiles?.find(
          tile => tile.productId === product.productId,
        )?.tileId;

        if (tileIdFromProductId) {
          const toggleAccess = await toggleChildUserAccess(
            childUser?.userId,
            product,
            globalUser?.account,
            tileIdFromProductId,
            true,
            publishTileEvent,
            userLite,
            userTileState,
          );

          if (toggleAccess.success) {
            // enqueueSnackbar(t('miop.userProfile.childUser.snackbar.emailReactivated'));
            setActionLoading(undefined);
            setChildEmailProductStatus('active');
            dispatchUserProfileState?.({
              type: 'UPDATE_PRODUCT_STATUS',
              payload: {
                userId: childUser.userId,
                productId: product.productId,
                status: 'active',
              },
            });
          }
        }
      }
    } catch (err) {
      setActionLoading(undefined);
    }
  };

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);

    try {
      const updatedUserObj = {
        userId: childUser?.userId || '',
        firstName: values.firstName,
        lastName: values.lastName,
        phone: getFormattedPhoneNumber(values.phone, country ?? 'US'),
      };

      const updateUserRes = await updateUser(updatedUserObj).unwrap();
      setChildUserFirstname(updateUserRes.firstName);
      setChildUserLastname(updateUserRes.lastName);
      dispatchUserProfileState?.({
        type: 'UPDATE_FILTERED_USER',
        payload: { ...updatedUserObj, ...childUser, email: childUser.email },
      });
      setIsSubmitting(false);
      toast({
        description: t('miop.userProfile.childUser.snackbar.updated', {
          name: updateUserRes?.firstName,
        }),
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (err) {
      setIsSubmitting(false);
      toast({
        description: t('miop.userProfile.childUser.snackbar.error'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const headingSize = useBreakpointValue({ base: 'hs-md', md: 'hs-lg' });

  return (
    <>
      <AccordionButton width="full" py={6} px={[6, null, 8]}>
        <ChildUserAccordionTitle
          firstName={childUserFirstname ?? ''}
          lastName={childUserLastname ?? ''}
          phone={childUser?.phone ?? ''}
          userStatus={childUser?.userStatus ?? ''}
          isOpen={isExpanded}
          avatarColor={avatarColor}
          setEditorOpen={setEditorOpen}
          isResendEmailLoading={isResendEmailLoading}
          childUserAvatar={childUserAvatar ?? ''}
          resendEmailHandler={
            childUser.userStatus === 'pending' || childUser.phone === null
              ? () => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  childUser.email && handleResendEmail(childUser.email);
                }
              : undefined
          }
        />
      </AccordionButton>
      <AccordionPanel p={0}>
        <Flex
          className="child-accordion-content"
          width="full"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          px={[6, null, 8]}
          pt={4}
        >
          <Box>
            <Heading size={headingSize}>{t('miop.userProfile.childUser.header')}</Heading>
            <Text textStyle="md" mt={2}>
              {t('common.misc.allFieldsRequiredUnless')}
            </Text>
          </Box>
          <VStack as="form" flexDirection="column" width="full" maxWidth={425} mt={6} spacing={6}>
            <InputField
              register={register}
              name="firstName"
              autoFocus
              label={t('common.inputs.firstName.label')}
              errors={errors}
            />
            <InputField
              register={register}
              name="lastName"
              label={t('common.inputs.lastName.label')}
              errors={errors}
            />
            <PhoneNumberInputField
              name="phone"
              label={t('common.inputs.phoneNumber.label')}
              control={control}
              country={country}
              onCountryChange={onCountryChange}
              errors={errors}
            />

            <InputField
              isDisabled
              register={register}
              name="personalEmail"
              label={t('common.inputs.email.altLabel')}
              errors={errors}
            />
            {childUser?.mailbox && childUser.mailboxFQDN && (
              <InputField
                isDisabled
                register={register}
                name="companyEmail"
                label={t('common.inputs.email.brandedLabel')}
                errors={errors}
              />
            )}
          </VStack>
          <Box mt={10}>
            <Heading size={headingSize}>{t('miop.userProfile.childUser.productAccess')}</Heading>
            <Text textStyle="md" mt={2}>
              {t('miop.userProfile.childUser.accessSubheader')}
            </Text>
          </Box>
          <Flex flexDirection="column" width="full">
            {userProfileState?.eligibleChildProducts?.map(
              (product, i) =>
                product && (
                  <ChooseUserProduct
                    key={product?.name}
                    name={Object.keys(childProductValues)[i]}
                    productName={product?.name || ''}
                    productDescription={product?.description || ''}
                    actionLoading={actionLoading}
                    productImage={`${
                      process.env.REACT_APP_IMG_URL
                    }/dashboard_nextgen/grandio/images/products/logos/svg/${formatProductName(
                      product?.name,
                    )}.svg`}
                    isSelected={childProductValues?.[product?.slug]}
                    includedInMembership={product.ratePlans?.some(
                      plan =>
                        plan?.name !== 'None' &&
                        plan?.includedInMembership &&
                        plan?.productId !== process.env.REACT_APP_EMAIL_PRODUCTID,
                    )}
                    toggleHandler={() => {
                      handleModalOpen('manage', childUser, product);
                    }}
                    isDisabled={determineIfProductDisabled(
                      isParentEmailProductActive,
                      childProductValues.email_rackspace,
                      userProfileState.emailSeatCount?.remainingEntitledSeats,
                      childEmailProductStatus,
                    )}
                    isSuspended={childEmailProductStatus === 'disabled'}
                    productStatus={
                      product.slug === 'email_rackspace' ? childEmailProductStatus : null
                    }
                    activateAccountHandler={
                      userProfileState.emailSeatCount?.remainingEntitledSeats === 0
                        ? undefined
                        : () => {
                            handleModalOpen('manage', childUser, product);
                          }
                    }
                    deleteAccountHandler={() => {
                      handleModalOpen('manage', childUser, product);
                    }}
                    reactivateAccountHandler={() => {
                      handleReactivateSeat(childUser, product);
                    }}
                    warningMessage={
                      product.slug === 'email_rackspace' &&
                      !childProductValues.email_rackspace &&
                      isParentEmailProductActive &&
                      (userProfileState.emailSeatCount?.remainingEntitledSeats === 0 ||
                        isEmpty(userProfileState.emailSeatCount)) && (
                        <Alert status="warning" mt={4} textAlign="center">
                          <Box textAlign="center" mx="auto" maxWidth={378}>
                            <AlertTitle mb={2}>
                              {t('miop.userProfile.childUser.subscriptionWarning.header')}
                            </AlertTitle>
                            <AlertDescription>
                              {t('miop.userProfile.childUser.subscriptionWarning.subheader')}
                            </AlertDescription>
                            <Trans i18nKey="miop.userProfile.childUser.subscriptionWarning.cta">
                              <AlertDescription>
                                To add a user to email,
                                <Button
                                  variant="link"
                                  onClick={() => {
                                    handleModalOpen('purchase', childUser, product);
                                  }}
                                >
                                  purchase more seats.
                                </Button>
                              </AlertDescription>
                            </Trans>
                          </Box>
                        </Alert>
                      )
                    }
                  />
                ),
            )}
          </Flex>
        </Flex>

        <Box mt={10} mb={12} sx={{ textAlign: 'center' }}>
          <Button size="lg" isLoading={isSubmitting} onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </Box>
      </AccordionPanel>

      <Modal isOpen={isDeleteConfirmationOpen} onClose={setDeleteConfirmationClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <SuspendDeleteModal
            firstName={childUser.firstName ?? ''}
            loading={isButtonLoading}
            suspendDisabled={childEmailProductStatus === 'disabled'}
            suspendHandler={() => {
              handleSuspendOrDeleteSeat(
                'suspend',
                childUser,
                userProfileState.eligibleChildProducts?.find(
                  p => p.productId === process.env.REACT_APP_EMAIL_PRODUCTID,
                ),
              );
            }}
            deleteHandler={() => {
              handleSuspendOrDeleteSeat(
                'delete',
                childUser,
                userProfileState.eligibleChildProducts?.find(
                  p => p.productId === process.env.REACT_APP_EMAIL_PRODUCTID,
                ),
              );
            }}
          />
        </ModalContent>
      </Modal>
      <Modal isOpen={isActivationOpen} onClose={setIsActivationClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ActivationModal
            email={childUser.email ?? ''}
            clickHandler={() => {
              setIsActivationClose();
            }}
          />
        </ModalContent>
      </Modal>
      <Modal size="2xl" isOpen={isEditorOpen} onClose={setEditorClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Avatar Photo</ModalHeader>
          <ModalCloseButton />
          <ModalBody mx="auto">
            <PhotoEditor
            // userId={childUser.userId as string}
            // avatar={childUser.avatar}
            // onSave={setChildUserAvatar}
            // onDelete={() => {
            //   setChildUserAvatar('');
            // }}
            // close={() => {
            //   setEditorOpen(!isEditorOpen);
            // }}
            // setUserAvatar={setChildUserAvatar}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
